import { Frame } from "framer"
import { addPropertyControls, ControlType } from "framer"

export function Footer(props) {
    const {
        fontSize,
        color,
        textAlign,
        fontFamily,
        fontWeight,
        letterSpacing,
        lineHeight,
    } = props
    const currentYear = new Date().getFullYear()

    return (
        <Frame
            background="none"
            style={{
                fontSize,
                color,
                textAlign,
                fontFamily,
                fontWeight,
                letterSpacing,
                lineHeight,
                margin: 0,
                padding: 0,
                position: "absolute",
                top: 0,
                left: 0,
            }}
        >
            © 2004 - {currentYear}
        </Frame>
    )
}

// Adding property controls to the component
addPropertyControls(Footer, {
    fontSize: {
        type: ControlType.Number,
        title: "Font Size",
        defaultValue: 14,
        unit: "px",
    },
    color: {
        type: ControlType.Color,
        title: "Text Color",
        defaultValue: "#666",
    },
    textAlign: {
        type: ControlType.SegmentedEnum,
        title: "Text Align",
        options: ["left", "center", "right"],
        optionTitles: ["Left", "Center", "Right"],
        defaultValue: "left",
    },
    fontFamily: {
        type: ControlType.String,
        title: "Font Family",
        defaultValue: "inherit",
    },
    fontWeight: {
        type: ControlType.String,
        title: "Font Weight",
        defaultValue: "normal",
    },
    letterSpacing: {
        type: ControlType.Number,
        title: "Letter Spacing",
        defaultValue: 0,
        unit: "em",
    },
    lineHeight: {
        type: ControlType.Number,
        title: "Line Height",
        defaultValue: 1.5,
        unit: "",
    },
})
